import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';

class Support extends React.Component {

    compontentDidMount() {
        document.title = "Support | Symbev";
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Support</div>
                </div>
                <div className="text-center">
                    <h3>Need help? Call or email us 24/7</h3>
                    <h4>
                        <p>519 631-1881</p>
                        <p><b>By Email:</b> <a href="mailto:order@symbevbrands.com">order@symbevbrands.com</a></p>
                    </h4>
                </div>
            </ContentWrapper>
        );
    }
}

export default (Support);